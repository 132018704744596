import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components}>
    <h1 {...{
      "id": "custom-loader"
    }}>{`Custom loader`}</h1>
    <p>{`By design, the default MDX is very minimal and likely won’t see any additional features outside of the MDX spec.  However, webpack `}<a parentName="p" {...{
        "href": "https://webpack.js.org/contribute/writing-a-loader"
      }}>{`makes it straightforward`}</a>{` to write your own loader to add custom syntax support.`}</p>
    <p>{`Consider a scenario where you wanted to add frontmatter support to all your MDX documents.  You could achieve this with a remark plugin or a custom loader.  Here we’ll write a `}<a parentName="p" {...{
        "href": "https://github.com/c8r/x0/blob/master/lib/mdx-fm-loader.js"
      }}>{`custom loader`}</a>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`// lib/fm-loader.js
const matter = require('gray-matter')
const stringifyObject = require('stringify-object')

module.exports = async function (src) {
  const callback = this.async()
  const { content, data } = matter(src)

  const code = \`export const frontMatter = \${stringifyObject(data)}

\${content}\`
  
  return callback(null, code)
}
`}</code></pre>
    <p>{`The loader code above parses out the frontmatter, exports it as the named export `}<inlineCode parentName="p">{`frontMatter`}</inlineCode>{`, and then returns the rest of the document which will then be handled by `}<inlineCode parentName="p">{`@mdx-js/loader`}</inlineCode>{` and then `}<inlineCode parentName="p">{`babel-loader`}</inlineCode>{`.`}</p>
    <p>{`Then, you can use it with the following config:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`module.exports = {
  module: {
    rules: [
      {
        test: /\\.mdx?$/,
        use: [
          'babel-loader',
          '@mdx-js/loader',
          path.join(__dirname, './lib/fm-loader')
        ]
      }
    ]
  }
}
`}</code></pre>

    </MDXLayout>;
}
MDXContent.isMDXComponent = true;
      